import * as React from 'react';
import ReactCompareImage from 'react-compare-image';
import { Helmet } from 'react-helmet';
import { Parallax } from 'react-scroll-parallax';
import Companies from '../compontents/Companies/Companies';
import ContactUsButton from '../compontents/ContactUsButton/ContactUsButton';
import Layout from '../compontents/Layout/Layout';
import Services from '../compontents/Services/Services';
import BottomLogo from '../images/squarehook-logo.inline.svg';
import './index.scss'; // Current bad approach but allows styling of the carousel items.
type IndexPageProps = {
    location: any;
};

// markup
const IndexPage: React.FC<IndexPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <Helmet>
                <title>SquareHook - We Get IT Done!</title>
            </Helmet>

            {/* <MissionStatement /> */}
            {/* cloud cost control callout */}
            <div className="cccHeader">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-6 py-5 mainText">
                            <h2>Maximize Your Cloud ROI</h2>
                            <h3>Save up to 50% on your cloud costs without sacrificing performance.</h3>
                            <a className="button-primary mainText mt-5" href="/ccc-contact">
                                Get A Free Demo
                            </a>
                        </div>
                        <div className="col-md-6">
                            <img className="img-fluid" src="/images/ccc/ccc-logo-tagline-white.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <h2 className="center callout">Businesses Are Overpaying For Azure Services By At Least 30%</h2>
                    </div>
                    <div className="col-lg-6">
                        <h3 className="primary py-5">
                            And if you think that excludes you—well, you&apos;re in for a painful revelation.
                        </h3>
                        <p className="mainText">
                            It&apos;s not because you&apos;re using{' '}
                            <span style={{ fontStyle: 'italic' }}>too much</span> cloud. Many companies simply
                            don&apos;t know how to find the available cost savings. They assume that the price they see
                            is the <span style={{ fontStyle: 'italic' }}>only price</span>, and they end up paying
                            significantly more than they need to.
                        </p>
                    </div>
                </div>

                {/* what is finops section */}
                <div className="row mt-5">
                    <div className="col-lg-8">
                        <h3 className="pt-5">What is FinOps?</h3>
                        <h4>And how to make it work for you with Cloud Cost Control</h4>
                        <p>
                            FinOps is the combination of finance management and DevOps. It&apos;s an evolving cloud
                            financial management that enables organizations to maximize business value and act on
                            data-driven spending decisions.
                        </p>
                        <p style={{ fontStyle: 'italic' }}>
                            “If it seems that FinOps is about saving money, then think again.{' '}
                            <span className="emphasis">FinOps is about making money.</span>”
                        </p>
                        <p>
                            FinOps is the tool to maximize cloud ROI, have more detailed information to make better
                            decisions about what to spend and when.
                        </p>
                    </div>
                    <div className="col-lg-3 center">
                        <img
                            className="img-fluid"
                            src="/images/ccc/finops-cert.png"
                            alt="FinOps certified Practitioner"
                            style={{ maxWidth: '275px' }}
                        />
                        <p style={{ fontWeight: 'bold', fontStyle: 'italic' }}>SquareHook FinOps and Cloud Experts</p>
                    </div>
                </div>

                <h3 className="mt-5 center">
                    Unlock the full potential of your cloud and increase your ROI with{' '}
                    <span className="primary emphasis" style={{ fontStyle: 'italic' }}>
                        Cloud Cost Control
                    </span>
                    .
                </h3>
                <p className="center">
                    We created “Cloud Cost Control” to take the FinOps burden off your shoulders and ensure maximum cost
                    savings and efficiency for your cloud operations.
                </p>

                {/* Cost reduction section */}
                <div className="mx-auto" style={{ maxWidth: '850px' }}>
                    <ReactCompareImage
                        leftImage="/images/cost-compare-1.png"
                        rightImage="/images/cost-compare-2.png"
                        hover
                        sliderLineColor="#000000"
                    />
                </div>
                <p className="center mainText container960">
                    Even with no changes to your cloud productivity, this automated FinOps service can find you{' '}
                    <span className="emphasis">30%&ndash;50% in extra savings!</span>
                </p>

                <div className="row m-auto center my-5">
                    <div className="col-lg-3 col-6 ms-auto">
                        <a className="button-primary" href="/cloud-cost-control">
                            Learn More
                        </a>
                    </div>

                    <div className="col-lg-3 col-6 me-auto">
                        <a className="button-darkBlue" href="/ccc-contact">
                            Free Demo
                        </a>
                    </div>
                </div>
            </div>

            {/* start gray background sections */}

            <div className="graybg pb-5">
                <div className="container">
                    {/* sales letter section */}
                    <h2 className="center pt-5">Want to find out more about FinOps?</h2>
                    <h3 className="center primary">Get a free info document!</h3>
                    <div className="row container960">
                        <div className="col-md-4 center">
                            <img
                                className="img-fluid"
                                style={{ maxHeight: '290px', marginTop: '-10px' }}
                                src="/images/ccc/ccc-sales-letter.png"
                            />
                        </div>
                        <div className="col-md-8">
                            <p className="mainText">
                                Discover the power of FinOps and take control of your cloud costs with this
                                comprehensive guide.
                            </p>
                            <p className="mainText">
                                Learn key strategies to optimize your cloud spending, saving you substantial expenses
                                without compromising performance.
                            </p>
                            <a className="button-primary" href="/documents/finops-for-your-cloud.pdf" target="_blank">
                                Download Document
                            </a>
                        </div>
                    </div>
                    {/* what you get with CCC section */}
                    <h2 className="mt-5 pt-5">A Done-For-You Process</h2>
                    <h3 className="primary">What you can expect from Cloud Cost Control</h3>
                    <div className="row mt-5">
                        <div className="col-lg-2 col-sm-4 center">
                            <img
                                className="img-fluid"
                                style={{ maxHeight: '250px' }}
                                src="/images/ccc/detailed-breakdown-trans.png"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-8">
                            <h4>Free Detailed Analysis</h4>
                            <p>
                                Our team of cloud experts will start with a free detailed analysis and report on your
                                cloud usage and spending and identify actions that can help you start saving
                                immediately.
                            </p>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 col-sm-4 center">
                            <img
                                className="img-fluid"
                                style={{ maxHeight: '250px' }}
                                src="/images/ccc/savings-trans.png"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-8">
                            <h4>Discounts and Savings</h4>
                            <p>
                                Our FinOps cloud experts know the most effective pricing models, deal structures, and
                                hidden discounts to dramatically lower your fees, without losing any cloud
                                functionality.
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-2 col-sm-4 center">
                            <img
                                className="img-fluid"
                                style={{ maxHeight: '250px' }}
                                src="/images/ccc/management-trans.png"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-8">
                            <h4>Data-Driven Optimization</h4>
                            <p>
                                We&apos;ll remove waste that was found by the analysis. We then go beyond surface-level
                                optimizations and dig deep into your cloud usage, optimizing operations for maximum
                                efficiency and ROI.
                            </p>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-2 col-sm-4 center">
                            <img
                                className="img-fluid"
                                style={{ maxHeight: '250px' }}
                                src="/images/ccc/monitoring-trans.png"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-8">
                            <h4>Continuous Monitoring</h4>
                            <p>
                                Cloud costs and usage fluctuate over time, making continuous monitoring crucial. As you
                                grow and build new things, we consistently monitor and audit for additional savings.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* free chat call to action */}
            <div className="bgsection mb-5">
                <div className="container960 my-5">
                    <h2 className="center">Ready to start saving on your cloud?</h2>
                    <h3 className="primary center">
                        Give us a chat for a{' '}
                        <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>free savings analysis</span> of your
                        current system.
                    </h3>

                    <h3 className="center mt-5">What you&apos;ll get with a free chat:</h3>
                    {/* bigger-mid screens */}
                    <div className="row d-none d-md-flex">
                        <div className="col-md-4">
                            <h4>Free Analysis</h4>
                            <img className="img-fluid" src="/images/ccc/analysis.png" alt="analysis chart" />
                            <p>Thorough automated analysis examining 5 different areas.</p>
                        </div>
                        <div className="col-md-4">
                            <h4>Free Savings Report</h4>
                            <img className="img-fluid" src="/images/ccc/report.png" alt="report" />
                            <p>Comprehensive report shows specific areas where you can save.</p>
                        </div>
                        <div className="col-md-4">
                            <h4>Recommended Plan</h4>
                            <img className="img-fluid" src="/images/ccc/30-50.png" alt="analysis chart" />
                            <p>Recommended &quot;done-for-you&quot; process to start saving 30-50%. </p>
                        </div>
                    </div>

                    {/* smaller screens */}
                    <div className="row d-md-none mt-3">
                        <div className="col-3">
                            <img className="img-fluid" src="/images/ccc/analysis.png" alt="analysis chart" />
                        </div>
                        <div className="col-9">
                            <h4>Free Analysis</h4>
                            <p>Thorough automated analysis examining 5 different areas.</p>
                        </div>
                    </div>

                    <div className="row d-md-none mt-3">
                        <div className="col-3">
                            <img className="img-fluid" src="/images/ccc/report.png" alt="report" />
                        </div>
                        <div className="col-9">
                            <h4>Free Savings Report</h4>
                            <p>Comprehensive report shows specific areas where you can save.</p>
                        </div>
                    </div>

                    <div className="row d-md-none mt-3">
                        <div className="col-3">
                            <img className="img-fluid" src="/images/ccc/30-50.png" alt="analysis chart" />
                        </div>
                        <div className="col-9">
                            <h4>Recommended Plan</h4>
                            <p>Recommended &quot;done-for-you&quot; process to start saving 30-50%. </p>
                        </div>
                    </div>

                    <p className="center mainText pt-5">
                        <a className="button-primary" href="/ccc-contact">
                            Book A Chat
                        </a>
                    </p>
                </div>
            </div>
            <h3 className="center mb-5">Some of Our Clients</h3>
            <Companies />

            <Services />

            <div className="get_in_touch_section" data-testid="get-in-touch-section">
                <div className="get_in_touch_text p-2" data-testid="get-in-touch-text">
                    Contact us now to get your personalized solutions to your problems.
                </div>
                <div className="mt-sm-4" data-testid="get-in-touch-link">
                    <ContactUsButton />
                </div>
                <div className={'bottom_parallax_container'} data-testid="bottom-parallax-container">
                    <Parallax shouldAlwaysCompleteAnimation={true} translateX={[100, -5]}>
                        <div className="">
                            <BottomLogo alt="Squarehook Logo" />
                        </div>
                    </Parallax>
                </div>
            </div>
            {/* <StyledBorderAdder>
        {(width: number, height: number) => (
          <div className={'triangle_test'} style={{ width: `${width}px`, height: `${height}px` }}>
            Testing STuff woot woot
          </div>
        )}
      </StyledBorderAdder> */}
        </Layout>
    );
};

export default IndexPage;
