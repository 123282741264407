import React from 'react';
import { companies_container, companies_image } from './Companies.module.scss';

type CompaniesProps = {
    imgOnly?: boolean;
};

const Companies: React.FC<CompaniesProps> = ({ imgOnly = false }) => {
    return imgOnly ? (
        <img className={`${companies_image} col-12`} alt="Proud Customers" src="../../images/companies.png"></img>
    ) : (
        <div className={`${companies_container} mb-5`} data-testid="companies-container">
            <img className={`${companies_image} col-12`} alt="Proud Customers" src="../../images/companies.png"></img>
        </div>
    );
};

export default Companies;
