import React from 'react';
import { Card } from 'react-bootstrap';
import { card_col, card_row, service_card, services_section } from './Services.module.scss';

const ProcessNew: React.FC = () => {
    return (
        <div className={services_section} data-testid="services-section">
            <div className="services-container" data-testid="services-container">
                <h2 className="center">Our Other Services</h2>
                <div className="px-5">
                    <div className={`row ${card_row}`}>
                        <div className={`col-xl-3 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <Card data-testid="card-1" className={`${service_card} p-2 h-100`}>
                                <a href="/managed-it">
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-managed.png"
                                        alt="managed IT in the cloud"
                                    />

                                    <Card.Body>
                                        <Card.Title>Managed IT Services</Card.Title>
                                        <Card.Text>
                                            Focus on growing your business while we handle the technology. Get your own
                                            dedicated, well-trained IT team to streamline your operations and boost your
                                            bottom line.
                                        </Card.Text>
                                    </Card.Body>
                                </a>
                            </Card>
                        </div>
                        <div className={`col-xl-3 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <a href="/devops">
                                <Card data-testid="card-2" className={`${service_card} p-2 h-100`}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-devops.png"
                                        alt="devops and cloud"
                                    />

                                    <Card.Body>
                                        <Card.Title>DevOps &amp; Cloud</Card.Title>
                                        <Card.Text>
                                            Streamline your software development. Get faster time to market, improved
                                            performance, increased efficiency, and less errors and downtime. We&apos;ll
                                            help with automated processes and a continuous delivery pipeline.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>{' '}
                            </a>
                        </div>
                        <div className={`col-xl-3 col-lg-6 col-md-6 mb-4 clear-fix ${card_col}`}>
                            <a href="custom-software">
                                <Card data-testid="card-3" className={`${service_card} p-2 h-100`}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-software.png"
                                        alt="developing software"
                                    />
                                    <Card.Body>
                                        <Card.Title>Custom Software</Card.Title>
                                        <Card.Text>
                                            The perfect out of the box solution doesn&apos;t exist. Get a customer
                                            solution tailored to your exact needs. Get secure software that integrates
                                            with all the other systems you need and can scale up or down as business
                                            requires.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                        <div className={`col-xl-3 col-lg-6 col-md-6 mb-4 ${card_col}`}>
                            <a href="/web-development">
                                <Card data-testid="card-4" className={`${service_card} p-2 h-100`}>
                                    <img
                                        className="card-img-top"
                                        src="../../../images/services-card-web.png"
                                        alt="website design"
                                    />
                                    <Card.Body>
                                        <Card.Title>Custom Website Development</Card.Title>
                                        <Card.Text>
                                            Whether you just need a basic website or require complex solutions for your
                                            enterprise-level site, we specialize in creating user-friendly websites that
                                            drive engagement and conversions.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessNew;
